<template>
  <div class="ds-container">
    <section class="ds-header">
      <div class="ds-title">
        <h2>Lotes enviados</h2>
        <span id="help-icon" class="icon-box">
          <HelpCircle class="icon stroke" />
        </span>
        <b-tooltip target="help-icon" placement="bottom">
          <div class="infoTooltip">
            <p><strong>Tipos de situação do lote:</strong></p>
            <p>
              <strong>Enviado </strong> - O lote foi enviado para a operadora,
              mas a operadora ainda não retornou.
            </p>
            <p>
              <strong>Recebido </strong> - A operadora retornou o lote com a
              análise de conta e/ou demonstrativo de pagamento.
            </p>
            <p>
              <strong>Faturado </strong> - Todas as guias do lote foram
              gerenciadas.
            </p>
            <p>
              <strong>Faturado parcial </strong> - Algumas guias do lote foram
              gerenciadas.
            </p>
            <p>
              <strong>Finalizado </strong> - Todas as guias foram gerenciadas e
              a operadora efetuou o pagamento do lote.
            </p>
            <p>
              <strong>Em análise </strong> - O lote de recurso está sendo
              elaborado, e a operadora ainda não retornou.
            </p>
          </div>
        </b-tooltip>
      </div>
      <div class="btn-title-container">
        <!-- <b-button
          v-if="showImportXml"
          variant="primary"
          @click="$bvModal.show('import-tiss-xml-modal')"
        >
          <UploadIcon class="upload-icon" />
          Importar XML
        </b-button> -->
        <b-button
          variant="outline-primary"
          @click="$bvModal.show('print-batch-modal')"
        >
          Folha de rosto
        </b-button>
        <Loading :class="{ icon: true, reload: true, loading }" @click="() => !loading && getBatches()"/>
        <!-- <b-button variant="outline-primary no-print" @click="print">Imprimir</b-button> -->
      </div>
    </section>
    <section>
      <SentLotsFilter 
        :statusOptions="statusOptions"
         @change="onChangeFilter" 
         class="no-print" 
      />
    </section>
    <section v-if="batches.length">
      <SentLotsContainer
        v-for="batch in batches"
        :key="batch.id"
        :batch="batch"
        :getGuide="getGuide"
        :openHistoric="openHistoric"
        :openDocumentModal="openDocumentModal"
        @onUpdate="getBatches"
        @updateGuideRows="handleGuideRows"
        @openDocumentListModal="openDocumentListModal"
        @openInfo="openInfoSidebar"
        @onManageGuides="openManageGuides"
        @finishBatch="finishBatch"
        @openFileModal="openModalBatchFilesUpload"
      />

      <div class="pagination-position">
        <b-pagination
          size="sm"
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          first-number
          last-number
        />
      </div>
    </section>
    <NoFiltersResults v-else message="Ainda não há lotes disponíveis." />
    <TissGuideAuditModal :tissGuideAudits="tissGuideAudits" />

    <TissGuidesModalsContainer
      :patient="patient"
      :tissGuide="tissGuide"
      :clinicHealthPlan="clinicHealthPlan"
      :appointmentId="tissGuide.appointment_id"
      :getLastTissGuide="getLastTissGuide"
      :clearTissGuide="clearTissGuide"
      :required_fields="tissRequiredCustomField"
      readonly
      :setTissGuide="setTissGuide"
      :getGuide="getGuide"
    />

    <TissGuideFilesContainer
      :file="file"
      :tissGuide="tissGuide"
      :tissGuides="guidesRows"
      :openDocumentModal="openDocumentModal"
      :openEditFileModal="openEditFileModal"
      :clearFile="clearFile"
      @hide-file-list-modal="hideFileListModal"
      :files="files"
    />

    <GuidesInfoSidebar
      :batchId="selectedBatchId"
      @updateGuide="getBatches"
    />

    <ImportTissXMLModal :getBatches="getBatches" />
    <TissBatchFilesModal :batchId="selectedBatchId"/>

    <BillsToReceiveTissModal 
      :billToReceive="billToReceive" 
      @reload="getBatches"
      @hide="billToReceive = null"
    />
  </div>
</template>

<script>
import api from '@/modules/tiss/manageGuides/api'
import { TissBatch }  from '@/modules/tiss/manageGuides/utils/statuses'
import { getCurrentClinic } from '@/utils/localStorageManager'
import { handleGuideUpdate } from '@/utils/invoicingTissHelper'
import { mapState } from 'vuex'

export default {
  props: {
    print: Function
  },
  components: {
    Loading: () => import('@/assets/icons/loading.svg'),
    HelpCircle: () => import('@/assets/icons/help-circle.svg'),
    // UploadIcon: () => import('@/assets/icons/upload.svg'),
    SentLotsContainer: () => import('@/modules/tiss/manageGuides/layouts/SentLotsContainer'),
    SentLotsFilter: () => import('@/components/Tiss/Generic/Filters/SentLotsFilter.vue'),
    TissGuideAuditModal: () => import('@/components/Tiss/Modal/TissGuideAuditModal.vue'),
    TissGuidesModalsContainer: () => import('@/layouts/InvoicingTiss/TissGuide/TissGuidesModalsContainer.vue'),
    TissGuideFilesContainer: () => import('@/layouts/InvoicingTiss/TissGuide/TissGuideFilesContainer.vue'),
    NoFiltersResults: () => import('@/components/General/noFilterResults'),
    ImportTissXMLModal: () => import('@/components/Tiss/Modal/ImportTissXMLModal'),
    GuidesInfoSidebar: () => import('@/modules/tiss/manageGuides/sidebar/GuidesInfoSidebar.vue'),
    BillsToReceiveTissModal: () => import('@/modules/financial/modals/BillsToReceiveTissModal'),
    TissBatchFilesModal: () => import('@/modules/tiss/manageGuides/components/TissBatchFilesModal'),
  },
  async mounted() {
    await this.getBatches()
  },
  data() {
    return {
      loading: false,
      clinic: getCurrentClinic(),
      statusOptions: [
        TissBatch.BATCH_STATUS_SENT,
        TissBatch.BATCH_STATUS_RECEIVED,
        TissBatch.BATCH_STATUS_INVOICED_PARTIAL,
        TissBatch.BATCH_STATUS_INVOICED
      ],
      batches: [],

      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      filters: {
        tab: 'sent',
      },

      batchSelected: [],
      modalSelectedBatch: {},
      tissGuideAudits: [],
      file: {},
      files: [],
      patient: {},
      tissGuide: {},
      clinicHealthPlan: {},
      guidesRows: [],
      tissRequiredCustomField: {},
      selectedBatchId: null,
      isOpenInfoSidebar: false,
      billToReceive: null
    }
  },
  computed: {
    ...mapState({
      reloadContent: state => state.tiss.reloadContent
    }),
    showImportXml() {
      return process.env !== 'production'
    }
  },
  methods: {
    handleGuideRows(value) {
      this.guidesRows = this.guidesRows.concat(value)
    },
    async onChangeFilter(newFilter) {
      this.filters = newFilter
      await this.getBatches()
    },
    async getBatches() {
      this.loading = true

      const filters = { ...this.filters }
      if (!filters?.status?.length) {
        filters.status = this.statusOptions
      }
      filters.type = filters.type?.value

      try {
        this.batches = []
        this.tableRows = []
        this.batchSelected = []

        const { data } = await api.getTissBatches(
          this.clinic.id,
          this.currentPage,
          filters
        )

        this.batches = data.data
        this.currentPage = data.current_page
        this.perPage = data.per_page
        this.totalItems = data.total
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    async finishBatch(batch) {
      try {
        const { data } = await api.getBatchBillData(batch.id)
        this.billToReceive = data
        this.$bvModal.show('bills-to-receive-tiss-modal')
      } catch (err) {
        this.$toast.warning(err.message)
      } finally {

      }      
    },
    async openManageGuides(batchInfo) {
      if (!batchInfo) return
      if (!this.reloadContent) {
        await this.$router.push({
          name: 'tiss-manage-guides',
          params: { batchId: batchInfo.batch.id }
        })
      }
    },
    openInfoSidebar(id) {
      this.selectedBatchId = id
      this.$root.$emit('bv::toggle::collapse', 'guides-info-sidebar')
    },

    parseDate(date) {
      return this.moment(date).format('DD/MM/YYYY')
    },
    async getGuide(tissGuide, notOpen) {
      const isLoading = this.$loading.show()
      try {
        const id = tissGuide?.id ?? tissGuide
        const response = await this.api.getTissGuideById(id)
        this.tissGuide = response.data

        if (notOpen) return

        this.patient = this.tissGuide.patient
        await this.getClinicHealthPlan(this.tissGuide.invoicing_tiss_setting_id)
        this.handleGuide(this.tissGuide.guide_type)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async getLastTissGuide(data) {
      await this.getGuide(data?.guide_id, true)
    },
    async getClinicHealthPlan(invoicingTissSettingId) {
      if (!this.clinic.id || !invoicingTissSettingId) return
      try {
        const response = await this.api.findByClinicIdAndInvoicingTissSettingId(
          this.clinic.id,
          invoicingTissSettingId
        )
        this.clinicHealthPlan = response.data
        this.tissRequiredCustomField =
          this.clinicHealthPlan.invoicing_tiss_setting.tiss_information.tiss_required_custom_field
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    openDocumentModal(tissGuide) {
      this.tissGuide = tissGuide
      this.$bvModal.show('tiss-guide-file-modal')
    },
    async getTissGuideFiles(tissGuideId) {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getTissGuideFiles(tissGuideId)
        return response.data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async openHistoric(tissGuideId) {
      await this.getTissGuideAudits(tissGuideId)
      this.$bvModal.show('tiss-guide-audit-modal')
    },
    async getTissGuideAudits(tissGuideId) {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getTissGuideAudits(tissGuideId)
        this.tissGuideAudits = response.data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async openDocumentListModal(tissGuide) {
      this.tissGuide = tissGuide
      this.files = await this.getTissGuideFiles(tissGuide.id)
      this.$bvModal.show('tiss-guide-file-list-modal')
    },
    openEditFileModal(file) {
      this.file = file
      this.$bvModal.show('tiss-guide-file-modal')
    },
    openModalUpdateTiss({ tissGuide, type }) {
      this.tissGuide = tissGuide
      this.guide_type = type
      this.handleGuide()
    },
    clearFile() {
      this.file = {}
    },
    clearTissGuide() {
      this.tissGuide = {}
    },
    hideFileListModal(files) {
      if (files.length) {
        this.$set(this.tissGuide, 'files_count', files.length)
      } else {
        this.$set(this.tissGuide, 'files_count', 0)
      }
    },
    handleGuide(type) {
      const modal = handleGuideUpdate(type)
      this.$bvModal.show(modal)
    },
    setTissGuide(tissGuide) {
      this.tissGuide = null
      this.tissGuide = tissGuide
    },
    openModalBatchFilesUpload(id) {
      this.selectedBatchId = id
      this.$bvModal.show('tiss-batch-files-modal')
    }
  },
  watch: {
    currentPage: {
      handler: async function () {
        await this.getBatches()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ds-container {
  padding: 30px 10px;

  .ds-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 0 0;
  }

  .ds-title {
    h2 {
      display: inline-flex;
      align-items: center;
      font-family: 'Red Hat Display';
      font-weight: 500;
      font-size: 18px;
    }

    .icon {
      height: 24px;
      width: 24px;
      margin-left: 5px;
      stroke: var(--neutral-500);
    }
  }

  .btn-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    button {
      font-weight: bold;
    }

    .btn-primary {
      padding: 4px 15px;
    }

    .upload-icon {
      height: 22px;
      width: 22px;
      margin-right: 7px;
      margin-bottom: 3px;
      overflow: inherit;
      fill: var(--neutral-000);
    }
  }
}

.infoTooltip {
  display: flex;
  flex-direction: column;

  p {
    display: flex !important;
    margin-bottom: 20px;
  }
}
</style>